import { observable, action, computed, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Amount } from 'src_common/common/amount/Amount';
import { TrpcClient } from 'src/appState/TrpcClient';
import { PraxisDepositSteps } from './PraxisJourney';
import {
    PraxisValidationDepositSuccessResponse,
    praxisValidationDeposit,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/praxisBillingInfoFieldsValidation';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateAmountRequire, validateMinAmountNew } from 'src/domains/players/webview/components/ValidatorsNew';
import { amountViewToValue } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/amountViewToValue';

export class PraxisTabState {
    public stepsState: PraxisDepositSteps;

    @observable public errorMessage: Array<string> = [];
    @observable public isRequesting: boolean = false;

    public readonly depositAmount: FormInputState<string, Amount>;

    public constructor(
        private readonly account: AccountState,
        private readonly usersState: UsersState,
        private readonly trpcClient: TrpcClient,
        public readonly googleTagManager: GoogleTagManagerState,
        public readonly languagesState: LanguagesState,
        public readonly minDepositAmount: Amount
    ) {
        makeObservable(this);
        this.stepsState = new PraxisDepositSteps();
        this.depositAmount = FormInputState.new('')
            .map(validateAmountRequire)
            .map(
                validateMinAmountNew(
                    this.minDepositAmount,
                    this.languagesState.getTranslation(
                        'errors.min-deposit',
                        'Minimum deposit amount is {currencySymbol}{minValue}',
                        { currencySymbol: this.usersState.moneySymbol, minValue: this.minDepositAmount.value }
                    )
                )
            );
    }

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
    }

    public onChange = (): void => {
        const formatValue = amountViewToValue(this.depositAmount.value);
        this.depositAmount.setValue(formatValue);
        this.clearErrorMessage();
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.depositAmount.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.depositAmount.setValue('');
        }
        return this.depositAmount.setValue(amount.toFixed(2));
    };

    @computed public get isButtonDisabled(): boolean {
        if (this.depositAmount.result.value.type === 'error' || this.stepsState.step.type !== 'set-method') {
            return true;
        }
        return false;
    }

    public handleAdditionsChange = (amount: Amount): void => {
        this.depositAmount.setValue(amount.value);
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();
    };

    /* ringFencedFundsFlag start */
    @computed public get hasRingFencedFunds(): boolean {
        return this.usersState.basicData.valueReady?.ringFencedFunds ?? false;
    }
    /* ringFencedFundsFlag end*/

    private clearErrorMessage = (): void => {
        this.errorMessage = [];
    };

    @action public submitDepositForm = async (): Promise<void> => {
        this.clearErrorMessage();
        this.isRequesting = true;

        if (this.depositAmount.result.value.type === 'error') {
            console.error('submitDepositForm - wrong deposit amount');
            this.isRequesting = false;
            return;
        }

        const amountToDeposit = this.depositAmount.result.value.data.value;

        const basicData = this.account.usersState.basicData.valueReady ?? null;
        const validatedData = praxisValidationDeposit(basicData);

        if (validatedData?.type !== 'ok') {
            this.createErrorMessage('missing-fields', validatedData?.fieldsRequired);
            console.error('submitDepositForm - User is anonymous');
            this.isRequesting = false;
            return;
        }

        try {
            if (this.hasRingFencedFunds === false) {
                await this.usersState.onChangeRingFencedFlag();
            }

            await this.onInitiateDeposit(validatedData.data, amountToDeposit);
            this.isRequesting = false;
        } catch (e) {
            this.isRequesting = false;
            this.stepsState.redirectToFailureView('serverIssue');
            throw e;
        }
    };

    private onInitiateDeposit = async (
        billingInfoData: PraxisValidationDepositSuccessResponse['data'],
        amount: string
    ): Promise<void> => {
        const response = await this.trpcClient.client.praxis.initiateDeposit.mutate({
            body: {
                ...billingInfoData,
                amount,
            },
        });

        if (response.responseStatus === 'success') {
            this.stepsState.redirectToIframeView({ iframeUrl: response.data });
            return;
        }

        const error = response.data;

        if (error.message?.includes('minimum') === true) {
            this.createErrorMessage('minimum');
            return;
        } else if (error.message?.includes('limit-reached') === true) {
            this.createErrorMessage('limit-reached');
            return;
        } else if (error.message?.includes('deposits-not-allowed') === true) {
            this.createErrorMessage('deposits-not-allowed');
            return;
        } else if (error.message?.includes('invalid-data') === true) {
            this.createErrorMessage('invalid-data');
            return;
        } else if (error.message?.includes('declined') === true) {
            this.createErrorMessage('declined');
            return;
        } else if (error.code === 'validation-error') {
            this.createErrorMessage('validation-error');
            return;
        } else {
            this.stepsState.redirectToFailureView('serverIssue');
            return;
        }
    };

    public createErrorMessage = (errorType: string, requiredFields?: Array<string>): void => {
        switch (errorType) {
            case 'invalid-data':
                this.errorMessage.push(
                    this.languagesState.getTranslation('errors.invalid.general', 'Some fields are invalid.')
                );
                break;
            case 'minimum':
                this.errorMessage.push(
                    this.languagesState.getTranslation(
                        'account.top-up.errors.deposit-minimum-amount',
                        'Minimum deposit amount is £1'
                    )
                );
                break;
            case 'limit-reached':
                this.errorMessage.push(
                    this.languagesState.getTranslation(
                        'account.top-up.errors.limit-reached',
                        "Deposit can't exceed defined limit."
                    )
                );
                break;
            case 'deposits-not-allowed':
                this.errorMessage.push(
                    this.languagesState.getTranslation('errors.unauthorized-client', 'Your account is suspended.')
                );
                break;
            case 'declined':
                this.errorMessage.push(
                    this.languagesState.getTranslation(
                        'account.top-up.errors.declined',
                        'Your deposit has been declined, please contact your bank for further information'
                    )
                );
                break;
            case 'validation-error':
                this.errorMessage.push(
                    this.languagesState.getTranslation('errors.unauthorized-client', 'Your account is suspended.')
                );
                break;
            case 'missing-fields':
                if (requiredFields === undefined) {
                    return;
                }

                this.errorMessage.push(
                    `Please add customer ${requiredFields.join(', ')} to customer 'Personal account' details.`
                );
                break;
            default:
                this.errorMessage.push(`Unknown error happen - ${errorType}`);
        }
    };
}
