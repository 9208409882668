import React, { useState } from 'react';
import { useCommon } from 'src/domains/common/Common';
import { observer } from 'src/utils/mobx-react';
import { YaspaIframe } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaIframe';
import {
    DarkLoadingSpinner,
    LoadingSpinner,
} from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { DepositFailure } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { DepositSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/depositSuccess/DepositSuccess';
import { useAppStateContext } from 'src/appState/AppState';
import { YaspaDepositState } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaDeposit.state';

type BaseYaspaDepositPropType = {
    accountHelperMail: string;
    isSignup: boolean;
    hideDepositSuccess: () => void;
    className?: string;
};

type IframeViewPropsType = BaseYaspaDepositPropType & {
    yaspaView: 'iframe-view';
    src: string;
    onMessage: () => void;
};

type LoadingViewPropsType = BaseYaspaDepositPropType & {
    yaspaView: 'loading-view';
};

type SuccessViewPropsType = BaseYaspaDepositPropType & {
    yaspaView: 'success-view';
};

export type YaspaDepositPropsType = IframeViewPropsType | LoadingViewPropsType | SuccessViewPropsType;

export const YaspaDeposit = observer('YaspaDeposit', (props: YaspaDepositPropsType): JSX.Element => {
    const { className, yaspaView, accountHelperMail, isSignup, hideDepositSuccess } = props;
    const common = useCommon();
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();
    const [stateLocal] = useState(() => new YaspaDepositState(usersState, common, yaspaView));
    const loadingType = isSignup === true ? <LoadingSpinner /> : <DarkLoadingSpinner />;

    const renderContent = (): JSX.Element => {
        switch (stateLocal.step.type) {
            case 'iframe-view':
                if (yaspaView !== 'iframe-view') {
                    return loadingType;
                }
                return (
                    <YaspaIframe
                        className={className}
                        src={props.src}
                        onMessage={props.onMessage}
                    />
                );
            case 'loading-view':
                return loadingType;
            case 'failure-view':
                return (
                    <DepositFailure
                        isSignup={isSignup}
                        issueType={stateLocal.step.failType}
                        accountHelperMail={accountHelperMail}
                        transactionCallback={
                            'failWithReceipt' === stateLocal.step.failType ? stateLocal.step.data : undefined
                        }
                        hideDepositSuccess={hideDepositSuccess}
                    />
                );
            case 'success-view':
                return (
                    <DepositSuccess
                        isSignup={isSignup}
                        balance={stateLocal.showBalance}
                        hideDepositSuccess={hideDepositSuccess}
                    />
                );
        }
    };
    return renderContent();
});
