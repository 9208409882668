import { CreateTRPCProxyClient, createTRPCProxyClient, httpLink /*loggerLink*/ } from '@trpc/client';
import { TrpcAppRouterType } from 'src_server/trpc/TrpcRouter';
import { Session } from 'src_common/sdk/session';

export class TrpcClient {
    public readonly client: CreateTRPCProxyClient<TrpcAppRouterType>;

    public constructor(isBrowser: boolean, session: Session, isMobileAppInReview: boolean) {
        const client: CreateTRPCProxyClient<TrpcAppRouterType> = createTRPCProxyClient<TrpcAppRouterType>({
            links: [
                // loggerLink<TrpcAppRouterType>(),
                httpLink<TrpcAppRouterType>({
                    url: '/trpc',
                    fetch: (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
                        if (isBrowser) {
                            return fetch(input, init);
                        } else {
                            return new Promise(() => {});
                        }
                    },
                    headers: () => {
                        const currentJwt = session.currentJwt;
                        const isAppInReview = isMobileAppInReview ? { 'x-app-review-token': 'app-in-review' } : {};
                        return {
                            Authorization: currentJwt,
                            ...isAppInReview,
                        };
                    },
                }),
            ],
        });

        this.client = client;
    }
}
