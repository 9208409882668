import React from 'react';
import {
    WidgetImageDefault,
    WidgetItemName,
    WidgetItemPrice,
    WidgetListItem,
    WidgetListWrapper,
    WidgetPictureWrapper,
    WidgetWrapper,
} from 'src/domains/casino/webview/CasinoGames/jack-pot-widget/JackPotWidget.style';
import { deviceSize } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { observer } from 'src/utils/mobx-react';
import mobileDreamDropLogo from './logo-mobile.png';
import desktopDreamDropLogo from './logo-desktop.png';

export const JackPotWidget = observer('JackPotWidget', (): JSX.Element => {
    return (
        <WidgetWrapper>
            <WidgetPictureWrapper>
                <source
                    media={`(min-width: ${deviceSize.largeDesktop}px)`}
                    srcSet={desktopDreamDropLogo}
                />
                <WidgetImageDefault
                    src={mobileDreamDropLogo}
                    alt='DreamDrop Jackpots logotype'
                />
            </WidgetPictureWrapper>

            <WidgetListWrapper>
                <WidgetListItem>
                    <WidgetItemName>Mega</WidgetItemName>
                    <WidgetItemPrice>$2,864,035.71</WidgetItemPrice>
                </WidgetListItem>

                <WidgetListItem>
                    <WidgetItemName>Maxi</WidgetItemName>
                    <WidgetItemPrice>$43,000</WidgetItemPrice>
                </WidgetListItem>

                <WidgetListItem>
                    <WidgetItemName>Midi</WidgetItemName>
                    <WidgetItemPrice>$1,000</WidgetItemPrice>
                </WidgetListItem>
            </WidgetListWrapper>
        </WidgetWrapper>
    );
});
