import { useAppStateContext } from 'src/appState/AppState';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { observer } from 'src/utils/mobx-react';
import { Amount } from 'src_common/common/amount/Amount';
import {
    RowRecordStyleBonuses,
    RowsContainerBonuses,
} from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/AccountSummarryBonuses/AccountSummaryBonuses.style';
import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Card } from 'src/domains/players/webview/components/Account/common/card/Card';
import {
    BalancesCardContainer,
    PaymentButton,
    PaymentButtonContainer,
    RowStyle,
    TotalBalanceValue,
    ValueStyle,
} from './BalancesCard.style';
import { AccountSummaryState } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/AccountSummary.state';
import { TooltipSecondary } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/ToolTipSecondary/TooltipSecondary';
import { BonusBalanceModel } from 'src/domains/players/state/BonusBalanceModel';

interface CardBalancesPropsType {
    accountSummaryState: AccountSummaryState;
}

export const CardBalances = observer('CardBalances', ({ accountSummaryState }: CardBalancesPropsType) => {
    const {
        appPlayersState: { usersState },
        common,
    } = useAppStateContext();

    const walletData = usersState.walletData.valueReady;

    const totalBalance = new Amount(walletData?.playableBalance ?? '0');
    const withdrawableBalance = new Amount(walletData?.withdrawableBalance ?? '0');
    const backofficeWithdrawableBalance = new Amount(
        usersState.walletData.valueReady?.backofficeWithdrawableBalance ?? '0'
    );
    const failedWithdrawalsFunds = backofficeWithdrawableBalance.sub(withdrawableBalance);
    const bonusBalanceModel = BonusBalanceModel.get(common);

    React.useEffect(() => {
        bonusBalanceModel.bonusWinningsBalances.refresh();
    }, []);

    return (
        <Card>
            <BalancesCardContainer>
                <RowRecordStyleBonuses>
                    <TotalBalanceValue data-test='total-balance-name'>
                        <I18n
                            langKey='account.summary.total'
                            defaultText='Total'
                        />
                    </TotalBalanceValue>
                    <TotalBalanceValue data-test='total-balance-value'>
                        {BasicDataModel.get(common).money(totalBalance)}
                    </TotalBalanceValue>
                </RowRecordStyleBonuses>
                <RowsContainerBonuses>
                    <RowRecordStyleBonuses>
                        <RowStyle>
                            <I18n
                                langKey='account.summary.withdrawal'
                                defaultText='Withdrawal'
                            />
                            <TooltipSecondary
                                content={
                                    <I18n
                                        langKey='account.summary.withdrawal.text'
                                        defaultText='The amount available for withdrawal. It does not include pending bonuses.'
                                    />
                                }
                            />
                        </RowStyle>
                        <ValueStyle>{BasicDataModel.get(common).money(withdrawableBalance)}</ValueStyle>
                    </RowRecordStyleBonuses>
                    <RowRecordStyleBonuses>
                        <RowStyle type='primary'>
                            <I18n
                                langKey='account.summary.bonus-balance'
                                defaultText='Bonus Balance'
                            />
                        </RowStyle>
                        <ValueStyle>{BasicDataModel.get(common).money(bonusBalanceModel.bonusBalanceSum)}</ValueStyle>
                    </RowRecordStyleBonuses>
                    {failedWithdrawalsFunds.isGreaterThanZero() ? (
                        <RowRecordStyleBonuses>
                            <RowStyle type='secondary'>
                                <I18n
                                    langKey='account.summary.failed-withdraw'
                                    defaultText='Failed Withdrawal'
                                />
                                <TooltipSecondary
                                    isError={true}
                                    content={
                                        <I18n
                                            langKey='account.summary.failed-withdrawal-funds.text'
                                            defaultText="We processed your withdrawal, but the bank returned the funds. Please provide a copy of your card showing your BACS information to enable us to pay your withdrawal. If you've already provided this, we will process your withdrawal shortly."
                                        />
                                    }
                                />
                            </RowStyle>

                            <ValueStyle type='secondary'>
                                {BasicDataModel.get(common).money(failedWithdrawalsFunds)}
                            </ValueStyle>
                        </RowRecordStyleBonuses>
                    ) : null}
                </RowsContainerBonuses>
                <PaymentButtonContainer>
                    <PaymentButton
                        version='primary'
                        dataTest='deposit-button'
                        size='medium'
                        onClick={accountSummaryState.redirectToTopUp}
                    >
                        <I18n
                            langKey='account.summary.deposit'
                            defaultText='Deposit'
                        />
                    </PaymentButton>
                    <PaymentButton
                        version='secondary-light'
                        size='medium'
                        dataTest='withdraw-button'
                        onClick={accountSummaryState.redirectToWithdraw}
                    >
                        <I18n
                            langKey='account.summary.withdraw'
                            defaultText='Withdraw'
                        />
                    </PaymentButton>
                </PaymentButtonContainer>
            </BalancesCardContainer>
        </Card>
    );
});
