import * as t from 'io-ts';
import { z } from 'zod';

/* UNIVERSE TYPE */
export const UniverseIO = t.union([
    //star template
    t.literal('akbets'),
    t.literal('betzone'),
    t.literal('bresbet'),
    t.literal('dragonbet'),
    t.literal('energy'),
    t.literal('latam'),
    t.literal('luckyking'),
    t.literal('mcbookie'),
    t.literal('nebet'),
    t.literal('oddsbet'),
    t.literal('planetsportbet'),
    t.literal('playbookrow'),
    t.literal('rhino'),
    t.literal('star'),
    t.literal('summitbet'),
    t.literal('swiftyglobal'),
    t.literal('tecbet'),
    t.literal('vickers'),
    t.literal('yeeehaaa'),
    t.literal('gentlemanjim'),
    t.literal('pricedup'),
    t.literal('betwright'),
    t.literal('bestodds'),
]);

/* UNIVERSE TYPE ZOD*/
export const UniverseZod = z.union([
    z.literal('akbets'),
    z.literal('betzone'),
    z.literal('bresbet'),
    z.literal('dragonbet'),
    z.literal('energy'),
    z.literal('luckyking'),
    z.literal('mcbookie'),
    z.literal('nebet'),
    z.literal('planetsportbet'),
    z.literal('rhino'),
    z.literal('star'),
    z.literal('summitbet'),
    z.literal('swiftyglobal'),
    z.literal('tecbet'),
    z.literal('vickers'),
    z.literal('yeeehaaa'),
    z.literal('playbookrow'),
    z.literal('latam'),
    z.literal('oddsbet'),
    z.literal('gentlemanjim'),
    z.literal('pricedup'),
    z.literal('betwright'),
    z.literal('bestodds'),
]);

export type UniverseType = t.TypeOf<typeof UniverseIO>;

export const getAllUniverse = (): Array<UniverseType> => {
    return [
        'akbets',
        'betzone',
        'bresbet',
        'dragonbet',
        'energy',
        'latam',
        'luckyking',
        'mcbookie',
        'nebet',
        'oddsbet',
        'planetsportbet',
        'playbookrow',
        'rhino',
        'star',
        'summitbet',
        'swiftyglobal',
        'tecbet',
        'vickers',
        'yeeehaaa',
        'gentlemanjim',
        'pricedup',
        'betwright',
        'bestodds'
    ];
};

/**
 * @deprecated - Please use the getAllUniverse function
 */
export const getAllUniverseStr = (): Array<string> => {
    return getAllUniverse();
};
