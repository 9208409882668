import { computed, makeObservable, action } from 'mobx';
import {
    CasinoCollectionModelExt,
    CasinoNavigationState,
    CollectionType,
} from 'src/domains/casino/state/AppCasino.state';
import { scrollToNavigationBar } from 'src/domains/casino/utils/utils';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { RedirectState } from './Redirect.state';
import { CasinoSearchExt } from './store/game-store/CasinoSearchExt';
import { ConfigState } from './ConfigState';

export class CasinoPageState {
    public readonly navigationState: CasinoNavigationState;
    private inputRef: HTMLInputElement | null = null;
    private navRef: HTMLInputElement | null = null;
    private gamesRef: HTMLInputElement | null = null;

    public constructor(
        private readonly languagesState: LanguagesState,
        private readonly redirectState: RedirectState,
        private readonly breakpointsState: BreakpointsState,
        private readonly config: ConfigState,
        private readonly collection: CasinoCollectionModelExt,
        private readonly collectionType: CollectionType,
        private readonly tabId: string | null,
        public readonly casinoSearchExt: CasinoSearchExt
    ) {
        makeObservable(this);

        this.navigationState = new CasinoNavigationState(
            this.redirectState,
            this.languagesState,
            this.collection,
            this.collectionType,
            this.tabId,
            () => this.navigationOffset
        );
    }

    @computed private get navigationOffset(): number {
        const navHeight = this.navRef?.offsetHeight ?? 0;
        const gamesOffset = this.gamesRef?.getBoundingClientRect().top ?? 0;

        return gamesOffset - navHeight;
    }

    @computed private get navigationOffsetWithWindowScroll(): number {
        return this.navigationOffset + window.scrollY;
    }

    @computed public get showCategoryLoader(): boolean {
        return this.collection.isLoading;
    }

    @computed public get searchInputValue(): string {
        return this.collection.searchValue;
    }

    public setInputRef = (ref: HTMLInputElement | null): void => {
        this.inputRef = ref;
    };

    public setNavRef = (ref: HTMLInputElement | null): void => {
        this.navRef = ref;
    };

    public setGamesRef = (ref: HTMLInputElement | null): void => {
        this.gamesRef = ref;
    };

    public onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        scrollToNavigationBar(this.breakpointsState, this.config, this.navigationOffsetWithWindowScroll);
        this.collection.setSearchValue(event.target.value);
    };

    private clearQuery = (): void => {
        this.collection.setSearchValue('');
    };

    @action public clearQueryOrClose = (): void => {
        if (this.searchInputValue === '') {
            this.casinoSearchExt.setIsSearchOpen(false);
        }

        this.clearQuery();
    };

    @action public toggleSearchInput = (): void => {
        this.casinoSearchExt.toggleIsSearchOpen();

        if (this.casinoSearchExt.isSearchOpen) {
            this.handleFocus();
        } else {
            this.clearQuery();
        }
    };

    private handleFocus = (): void => {
        if (this.inputRef !== null) {
            this.inputRef.focus();
        }
    };
}
