import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { QuickHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader';
import { HeaderMainLinks } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/headerMainLinks/HeaderMainLinks';
import { HeaderWrapperList } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/headerWrapperList/HeaderWrapperList';
import { HamburgerMenu } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/hamburgerMenu/HamburgerMenu';
import { HeaderMainLogo } from 'src/domains/layouts/webview/components/HeaderMainLogo';
import { LifeSpanWallet } from 'src/domains/sportsbook/shared/Components';
import { useAppStateContext } from 'src/appState/AppState';
import { UnreadCount } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { LanguageSwitcher } from 'src/domains/layouts/webview/components/languageSwitcher/LanguageSwitcher';
import { useState } from 'react';
import { computed, makeObservable, observable } from 'mobx';

interface PropsType {
    currentView: RouteViewType;
    accountView: RightHandSideViewType | null;
}

class State {
    @observable public ref: HTMLElement | null = null;

    public setRef = (ref: HTMLElement | null): void => {
        this.ref = ref;
    };

    public constructor() {
        makeObservable(this);
    }

    @computed public get refWidth(): number {
        return this.ref?.clientWidth ?? 0;
    }
}

export const Header = observer('Header', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const {
        breakpointsState,
        lifeSpanState,
        configComponents: { config },
    } = appLayoutsState;
    const isLHSOpened = appLayoutsState.starRouter.isAccountParam;
    const routeName = props.currentView.name;
    const shouldShowLanguageSwitcher = config.languagesAvailable.length > 1;
    const isAnyCasino = routeName === 'casino' || routeName === 'virtuals' || routeName === 'live-casino';
    const isErrorPage = routeName === 'error';
    const [state] = useState(() => new State());
    const isDesktop = breakpointsState.desktop.isBiggerOrEq ?? false;
    const shouldHidePicker = isDesktop === false || isLHSOpened || shouldShowLanguageSwitcher === false;
    return (
        <HeaderWrapperList>
            <HamburgerMenu />
            <HeaderMainLogo />
            {breakpointsState.largeDesktop.isBiggerOrEq === false ? null : (
                <HeaderMainLinks
                    currentView={props.currentView}
                    languageWidth={shouldShowLanguageSwitcher === false ? 0 : state.refWidth}
                />
            )}
            {shouldHidePicker ? null : (
                <LanguageSwitcher
                    reference={null}
                    setRef={state.setRef}
                    isSidebar={false}
                />
            )}
            {/* TODO remove when https://playbookengineering.atlassian.net/browse/LT-1550 will be ready */}

            {isErrorPage ? null : (
                <>
                    {breakpointsState.desktop.isBiggerOrEq === false ? (
                        <QuickHeader
                            isAnyCasino={isAnyCasino}
                            accountView={props.accountView}
                            currentView={props.currentView}
                        />
                    ) : null}
                    {breakpointsState.desktop.isBiggerOrEq === false && lifeSpanState.isWalletOpen === true ? (
                        <LifeSpanWallet />
                    ) : null}
                </>
            )}
        </HeaderWrapperList>
    );
});

export { HeaderMainLinks, UnreadCount };
