import { computed, toJS, makeObservable, observable, autorun } from 'mobx';
import { GameType } from 'src/domains/casino/shared/Types';
import { UserAttributionState } from 'src/domains/layouts/state/userAttributionState/userAttributionState';
import { AccountModel } from 'src/domains/players/shared/Types';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { iConvertDeposit, iConvertLogin, iConvertReg } from './iconvert';

const sortByDate = (a: { date: string }, b: { date: string }): number => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    if (dateA < dateB) {
        return 1;
    }
    if (dateA > dateB) {
        return -1;
    }
    return 0;
};

/*
    Next to each `window.dataLayer` is comment for what operator it was implemented.
    Intilery is third party which will manage CRM (customer relationship management) for our operators.
    Tags could vary between operators but these are exactly tags which they are listen to in GTM (google tag manager)
*/

const getRandomNumberId = (): number => Date.now() + Math.floor(Math.random() * 1000000000000);

interface EventViewTagPropsType {
    eventId: number;
    eventName: string | undefined;
    eventTime: string | undefined;
}

interface BetAddedPropsType {
    marketId: number;
    marketName: string | null | undefined;
    selectionId: number;
    selectionName: string | null | undefined;
    betEventId: number;
    betEventName: string | null | undefined;
    selectionPrice: { d: number; f: string } | undefined | null;
    type: string;
    sportId: number | string | undefined;
    sportName: string | undefined;
}

interface BetPlacedPropsType {
    betId: number;
    type: string;
    totalStake: string;
    totalStakeCurrency: string;
    freebet: boolean;
    legs: Array<{
        marketId?: string | number;
        marketName?: string | null;
        selectionId?: string | number;
        selectionName?: string | null;
        betEventId?: string | number;
        betEventName?: string;
        price?: number;
        sportId?: number | string;
        sportName?: string | null;
    }>;
    isBoostVisible: boolean;
    isBoosted: boolean | undefined;
}

export class GoogleTagManagerState {
    @observable private uuid;
    private readonly configComponents: ConfigComponents;
    private readonly starRouter: StarRouter;
    private readonly userAttributionState: UserAttributionState;

    public static get = AutoWeakMap.create((common: Common) => new GoogleTagManagerState(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);
        this.uuid = getRandomNumberId();
        this.configComponents = ConfigComponents.get(common);
        this.starRouter = StarRouter.get(common);
        this.userAttributionState = UserAttributionState.get(common);
    }

    @computed public get userId(): number | null {
        return this.common.session.userId;
    }

    public pushDataLayer = (data: Record<string, unknown>): void => {
        const { attributionsNormalized } = this.userAttributionState.userAttributionsLocalStorage;
        const user_attribution_normalized = toJS(attributionsNormalized);

        try {
            // @ts-expect-error
            window.dataLayer.push({
                user_id: this.userId,
                platform_user_id: this.userId,
                ...data,
                ...user_attribution_normalized,
            });
        } catch (error) {
            console.error('GTM is not defined');
        }
    };

    // Track deposit, first deposit and next deposit.
    public addDepositTag = (account: AccountModel | null): void => {
        // On Betzone request
        this.pushDataLayer({ event: 'deposit' });

        if (account !== null) {
            autorun((dispose) => {
                const depositList = account.userTransactionHistory.get([25, 0, undefined, undefined, 'deposit']).get();

                if (depositList.type === 'ready') {
                    const paidDeposits = depositList.value.data.filter((item) => item.status === 'paid');

                    const newDeposit = paidDeposits.sort(sortByDate)[0] ?? null;

                    if (newDeposit === null) {
                        return;
                    }

                    this.pushDataLayer({
                        event: 'deposit-confirm',
                        amount: newDeposit.balanceDelta,
                        currency: newDeposit.currency,
                    });

                    iConvertDeposit(newDeposit.balanceDelta, newDeposit.currency);

                    const dataLength = paidDeposits.length;

                    if (dataLength === 1) {
                        // On Betzone and StarSports request
                        this.pushDataLayer({
                            event: 'first-time-deposit',
                            amount: newDeposit.balanceDelta,
                            currency: newDeposit.currency,
                        });
                        dispose.dispose();
                        return;
                    }

                    if (dataLength > 1) {
                        // On StarSports request
                        this.pushDataLayer({
                            event: 'not-first-time-deposit',
                            amount: newDeposit.balanceDelta,
                            currency: newDeposit.currency,
                        });
                        dispose.dispose();
                        return;
                    }
                }
            });
        }
    };

    // Track users movement across the accounts section, request for for all operators:
    public routingAccountChange = (route: string): void => {
        this.pushDataLayer({
            event: 'accountNavigation',
            route: route,
            from: this.starRouter.currentView?.name ?? '',
        });
    };

    public addWithdrawnTag = (amount: number | string): void => {
        // on request for Intilery:
        this.pushDataLayer({ event: 'moneyWithdrawn', method: 'withdrawn', amount: amount });
    };

    public registerFinishedTag = (userId: number | undefined | null): void => {
        //Fired when newUser hit the button after filling form and request BE for CallCredit/Age Verification/GameStop to be passed

        // on request for Intilery:
        this.pushDataLayer({ event: 'registered', newUserId: userId });
        this.pushDataLayer({ newUserId: userId });
        // on request for planetsportbet:
        this.pushDataLayer({ registeredId: userId });
        // on request for betzone:
        this.pushDataLayer({ event: 'register' });
        // on request for mcbookie:
        this.pushDataLayer({ event: 'FormCompleted', form: 'Registration Complete' });

        iConvertReg();
    };

    public loginTag = (userId: number): void => {
        // on request for Intilery:
        this.pushDataLayer({ event: 'loggedIn', loggedInUserId: userId });
        // on request for planetsportbet and Intilery:
        this.pushDataLayer({ loggedInUserId: userId });

        iConvertLogin(userId);
    };

    // on request for Intilery:
    public gamePlayTag = (gameId: number | string, gameName: string | undefined, gameType: GameType): void => {
        this.pushDataLayer({ event: 'gamePlayed', gameId: gameId, gameName: gameName, gameType: gameType });
    };

    public sportViewTag = (sportId: string | null, sportName: string | undefined): void => {
        // on request for Intilery:
        this.pushDataLayer({ event: 'sportView', sportId: sportId, sportName: sportName });
    };

    public eventViewTag = ({ eventId, eventName, eventTime }: EventViewTagPropsType): void => {
        // on request for Intilery:
        this.pushDataLayer({ event: 'eventView', eventId: eventId, eventName: eventName, eventTime: eventTime });
    };

    public categoryViewTag = (route: string | undefined): void => {
        // on request for Intilery:
        this.pushDataLayer({ event: 'categoryViewed', categoryId: route, categoryName: route });
    };

    public betAddedTag = (betAddedProps: BetAddedPropsType): void => {
        const {
            marketId,
            marketName,
            selectionId,
            selectionName,
            betEventId,
            betEventName,
            type,
            selectionPrice,
            sportId,
            sportName,
        } = betAddedProps;

        const coalescePrice = selectionPrice ?? null;
        const price = coalescePrice === null ? null : coalescePrice.d;

        // on request for Intilery:
        this.pushDataLayer({
            event: 'betAdded',
            marketId: marketId,
            marketName: marketName,
            selectionId: selectionId,
            selectionName: selectionName,
            betEventId: betEventId,
            betEventName: betEventName,
            price: price,
            type: type,
            sportId: sportId,
            sportName: sportName,
        });
    };

    public betPlacedTag = (betPlacedProps: Array<BetPlacedPropsType>): void => {
        // on request for Intilery:
        betPlacedProps.map((singleBet) => {
            const totalStake = this.configComponents.precision.newFromAnything(singleBet.totalStake);

            this.pushDataLayer({
                event: 'betPlaced',
                betId: singleBet.betId,
                legs: singleBet.legs,
                totalStake: totalStake,
                totalStakeNum: parseFloat(singleBet.totalStake),
                totalStakeCurrency: singleBet.totalStakeCurrency,
                type: singleBet.type,
                freebet: singleBet.freebet,
                isBoostVisible: singleBet.isBoostVisible,
                isBoosted: singleBet.isBoosted,
            });
        });
    };

    public lifespanBoostAvailableTag = (campaignId: string | undefined): void => {
        // for all operators which has lifespan
        this.pushDataLayer({
            event: 'LIF_Reward',
            type: 'odds-boost',
            campaignId: campaignId,
        });
    };

    /* on request for all operator exclude Swifty */
    public depositedMoney = (amount: number, isSignUp: boolean): void => {
        this.pushDataLayer({
            event: 'depositedMoney',
            amount,
            isSignUp,
        });
    };

    /** * At the request of ForLoop - Registration process */
    public renderNewUuid = (): void => {
        this.uuid = getRandomNumberId();
    };

    /** * At the request of ForLoop - Registration process - Welcome page */
    public gtmSignUpWelcomePage = (): void => {
        this.pushDataLayer({
            event: 'welcome_page',
            pageID: 0,
            uuid: this.uuid,
        });
    };

    /** * At the request of ForLoop - Registration process - Your details */
    public gtmSignUpStepOne = (promoCode: string, date: string): void => {
        this.pushDataLayer({
            event: 'page1',
            pageID: 1,
            promoCode: promoCode,
            registrationDateAttempt: date,
            uuid: this.uuid,
        });
    };

    /** * At the request of ForLoop - Registration process - Account created, verification completed */
    public gtmSignUpStepTwo = (userState: UsersState): void => {
        autorun((dispose) => {
            const data = userState.walletData.get();
            const userBasicData = userState.basicData.get();

            if (data.type === 'ready' && userBasicData.type === 'ready') {
                this.pushDataLayer({
                    event: 'page2',
                    pageID: 2,
                    registration_date: data.value.createdAt,
                    user_verification_complete: 1,
                    uuid: this.uuid,
                    user_id: userBasicData.value?.id,
                });
                dispose.dispose();
                return;
            }
        });
    };

    /** * At the request of ForLoop - Registration process - For responsible gambling */
    public gtmSignUpStepThree = (
        responsibleGambling: string,
        isDepositLimit?: boolean,
        isRealityCheck?: boolean
    ): void => {
        this.pushDataLayer({
            event: 'page3',
            pageID: 3,
            responsible_gambling: responsibleGambling,
            is_deposit_limit: isDepositLimit === true ? 'activated' : null,
            is_reality_check: isRealityCheck === true ? 'activated' : null,
            uuid: this.uuid,
        });
    };

    /** * At the request of ForLoop - Registration process - For deposit */
    public gtmSignUpStepFour = (depositAmount: number): void => {
        this.pushDataLayer({
            event: 'page4',
            pageID: 4,
            deposit_amount: depositAmount,
            uuid: this.uuid,
        });
    };

    /** * At the request of ForLoop - Registration process - If user verification failed */
    public gtmSignUpVerificationFailed = (): void => {
        this.pushDataLayer({
            event: 'verification_failed_page',
            user_verification_complete: 0,
            uuid: this.uuid,
        });
    };
}
