import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    BulbMessage,
    PayoutRules,
    AcceptChanges,
    AcceptOfferBtn,
    PlaceButtons,
    LogInToPlaceBet,
} from './BetslipButtonsElements';
import { BetButtonsWrapper } from './BetSlipButtons.style';
import { BetslipFooter } from 'src/domains/sportsbook/betting/ui/betSlip/betslipFooter/BetslipFooter';
import { useAppStateContext } from 'src/appState/AppState';
import { useCommon } from 'src/domains/common/Common';

const BetSlipButtonsInner = observer('BetSlipButtonsInner', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const common = useCommon();
    const { session } = common;

    if (session.isAuthorized) {
        if (betSlipState.betSlipSummaryState.isLegsChangedWarning) {
            return <AcceptChanges />;
        } else if (betSlipState.referralState.isBetSlipOffer) {
            return <AcceptOfferBtn />;
        } else {
            return <PlaceButtons />;
        }
    } else {
        return <LogInToPlaceBet />;
    }
});

export const BetSlipButtons = observer('BetSlipButtons', () => {
    const { appLayoutsState, appSportsBookState, common } = useAppStateContext();
    const { lifeSpanState } = appLayoutsState;
    const { betSlipState } = appSportsBookState;

    const { isRejectedBet } = betSlipState.referralState;
    const { isShowQuickBet } = betSlipState.basicBetSlipState;
    const { isVisibleKeyBoard, focusedSelectionId } = betSlipState.basicBetSlipState;
    const showFreeBetButton =
        !common.features.bonusEngine &&
        betSlipState.freeBetsAmount !== null &&
        betSlipState.freeBetsAmount.isGreaterThanZero() &&
        lifeSpanState.lifeSpanSocketState.rewardValue.isEqualWithZero();

    if (isRejectedBet) {
        return null;
    }

    if (isShowQuickBet) {
        return (
            <>
                <BetButtonsWrapper visibleKeyboard={isVisibleKeyBoard && focusedSelectionId !== null}>
                    <BetSlipButtonsInner />
                </BetButtonsWrapper>
                {showFreeBetButton ? <BulbMessage /> : null}
                <PayoutRules />
            </>
        );
    }

    return (
        <BetButtonsWrapper visibleKeyboard={isVisibleKeyBoard && focusedSelectionId !== null}>
            <BetSlipButtonsInner />
            <BetslipFooter />
            {showFreeBetButton ? <BulbMessage /> : null}
            <PayoutRules />
        </BetButtonsWrapper>
    );
});
