import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import {
    IssueWithdrawContentContainer,
    IssueWithdrawInfo,
    IssueWithdrawTitle,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue.style';
import { CheckMarkIconWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess.style';

const WithdrawalPending = (
    <>
        <CheckMarkIconWrapper />

        <IssueWithdrawContentContainer>
            <IssueWithdrawTitle>
                <I18n
                    langKey='account.withdraw.pending.title'
                    defaultText='Withdrawal Pending'
                />
            </IssueWithdrawTitle>
            <IssueWithdrawInfo>
                <I18n
                    langKey='account.withdraw.pending.info--secondary'
                    defaultText="We've received your request. Once processed, the funds will be available in your bank account immediately or within two business days, depending on your bank."
                />
            </IssueWithdrawInfo>
        </IssueWithdrawContentContainer>
    </>
);

export const WithdrawSuccess = observer('WithdrawSuccess', () => {
    return WithdrawalPending;
});
