import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import JackPotMobileBg from './jackpot-mobile-bg.jpg';

export const WidgetWrapper = withConfig(theme => styled('div', { label: 'WidgetWrapper' })`
    background: ${theme.star.jackPotWidget.bgColor} url(${JackPotMobileBg}) no-repeat 0 0 / cover;
    border-radius: 8px;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    margin-right: 16px;
    padding: 8px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;
    }
`);


export const WidgetPictureWrapper = withConfig(theme => styled('picture', { label: 'WidgetPictureWrapper' })`
    @media ${theme.star.mediaQuery.largeDesktop} {
        display: flex;
    }
`);

export const WidgetImageDefault = withConfig(theme => styled('img', { label: 'WidgetImageDefault' })`
    display: block;
    height: 56px;
    margin: auto;
    width: 92px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        width: 160px;
    }
`);

export const WidgetListWrapper = withConfig(theme => styled('ul', { label: 'WidgetListWrapper' })`
    @media ${theme.star.mediaQuery.largeDesktop} {
        display: flex;
        flex-flow: row nowrap;
        gap: 8px;
    }
`);

export const WidgetListItem = withConfig(theme => styled('li', { label: 'WidgetListItem' })`
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-flow: row nowrap;
    font-weight: ${theme.star.fontWeight.bold};
    gap: 8px;
    justify-content: space-between;
    min-width: 172px;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.base};
    &::before {
        backdrop-filter: blur(8px);
        background: ${theme.star.jackPotWidget.bgColorSecondary};
        border: 1px solid ${theme.star.jackPotWidget.bgColorSecondary};
        border-radius: inherit;
        content: '';
        inset: 0;
        position: absolute;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    &:not(:last-of-type) {
        margin-bottom: 4px;
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        flex-direction: column;
        gap: 0;
        padding: 8px;
        &:not(:last-of-type) {
            margin-bottom: 0;
        }
    }
`);

export const WidgetItemName = withConfig(theme => styled('span', { label: 'WidgetItemName' })`
    color: ${theme.star.jackPotWidget.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    @media ${theme.star.mediaQuery.largeDesktop} {
        font-size: ${theme.star.fontSize.small};
        font-weight: ${theme.star.fontWeight.xMedium};
        line-height: 1.3334;
    }
`);

export const WidgetItemPrice = withConfig(theme => styled('span', { label: 'WidgetItemPrice' })`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    color: ${theme.star.jackPotWidget.txtColor};
    background: ${theme.star.jackPotWidget.txtColorSecondary};
    background-clip: border-box;
    -webkit-background-clip: text; /* stylelint-disable-line property-no-vendor-prefix */
    -webkit-text-fill-color: transparent;
    @media ${theme.star.mediaQuery.largeDesktop} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.4;
    }
`);
