import { z } from 'zod';
import { fetchPost } from 'src_common/common/realtime-server/fetch';
import { decodePostPlaceBetResponse, BettingPlaceBetType, NewTradingPostBetData } from './postPlaceBetTypes';
import { ChannelType, RabPostBodyItemType } from './types';
import {
    openapiBettingPlaceBetRequest,
    OpenapiBettingPlaceBetResponseType,
    ParamsTypeZOD,
    Response201ZOD,
    Response422ZOD,
} from 'src/api_openapi/generated/openapi_betting_placeBet';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { SelectedBonusType } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { Amount } from 'src_common/common/amount/Amount';

const InputBetSchema = ParamsTypeZOD.shape.requestBody.shape.bets;

export const PlaceBetNewSchema = z.object({
    bets: InputBetSchema,
    possibleBetSuccess: z.boolean(),
});

export type PlaceBetRequestBodyType = z.infer<typeof PlaceBetNewSchema>;

const placeBetBetsSchema = Response201ZOD.shape.data.shape.bets;

export type PlaceBetBetsType = z.infer<typeof placeBetBetsSchema>;

export const InternalPlaceBetErrors = ['request_fail', 'bet_params', 'possible_bet', 'retry-fail'] as const;
const InternalPlaceBetErrorsSchema = z.enum(InternalPlaceBetErrors);

export type InternalPlaceBetErrorsType = (typeof InternalPlaceBetErrors)[number];
export const InternalPlaceBetErrorsMessages: Record<InternalPlaceBetErrorsType, string> = {
    request_fail: 'Request failed',
    bet_params: 'Wrong bet params',
    possible_bet: 'Possible bet failed',
    'retry-fail': 'Bet retry failed',
};

export const ParsedPlaceBetSchema = z.union([
    z.object({
        status: z.literal('success'),
        data: placeBetBetsSchema,
    }),
    z.object({
        status: z.literal('error'),
        data: Response422ZOD,
    }),
    z.object({
        status: z.literal('internalError'),
        data: InternalPlaceBetErrorsSchema,
    }),
]);

export type ParsedPlaceBetType = z.infer<typeof ParsedPlaceBetSchema>;

export interface RabBetType {
    type: string;
    ip: string | undefined;
    stakePerLine: number | undefined;
    payout: number | undefined | null;
    eachWay: boolean;
    channel: ChannelType;
    platformId: string | null | undefined;
    correlationId: string;
    freeBets: Array<string> | null | undefined;
    freebetCredits: Array<{
        id: number;
        amount: string;
    }>;
    freebetRemarks: Array<{
        resource: string;
        code: string;
        details:
            | {
                  minimum: string;
              }
            | { invalid: string }
            | { negative: string };
    }>;
    legs: Array<{
        type: string | undefined;
        priceType: string;
        channel: ChannelType;
        sport: {
            id: string;
        };
        event: {
            id: number;
            externalId: string;
        };
        selections: RabPostBodyItemType[];
        price:
            | {
                  d: number;
                  f: string;
              }
            | null
            | undefined;
    }>;
}

export type PlaceBetRabBetType = Omit<RabBetType, 'stakePerLine'> & { stakePerLine: string };

export interface NewPlaceBetRequestType {
    channel: ChannelType;
    bets: Array<NewTradingPostBetData>;
    rabBets: Array<PlaceBetRabBetType> | null;
    possibleBetSuccess: boolean;
}

export const postPlaceBetNewTrading = async ({
    channel,
    bets,
    rabBets,
    possibleBetSuccess,
}: NewPlaceBetRequestType): Promise<BettingPlaceBetType> => {
    const response = await fetchPost({
        url: '/api/betslip/place-bet-from-website-bets',
        decode: decodePostPlaceBetResponse,
        body: { channel, bets, rabBets, possibleBetSuccess },
    });
    if (response.status === 200) {
        return {
            status: 'success',
            data: response.bodyJson,
        };
    }

    if (response.status === 400) {
        const { data, debug, errors: responseErrors } = response.bodyJson;
        // TODO: To be properly handled after betslip refactor
        const errors = Array.isArray(responseErrors) ? {} : responseErrors;
        return {
            status: 'error',
            data: data,
            debug: debug,
            errors,
        };
    }

    return null;
};

export const preparePlaceBetRequestBody = (
    data: NewPlaceBetRequestType,
    precision: AmountPrecision,
    selectedBonuses?: Map<string, Array<SelectedBonusType>>
): undefined | PlaceBetRequestBodyType => {
    const regularBets = data.bets
        .filter((bet) => precision.newFromAnything(bet.stakePerLine).isGreaterThanZero())
        .map((bet) => {
            const mappedBet = {
                ...bet,
                country: {
                    value: bet.country,
                },
                channel: data.channel,
            };

            if (mappedBet.freebetBonusSubtract !== null && mappedBet.freebetBonusSubtract !== undefined) {
                const freebetCredits = mappedBet.freebetBonusSubtract.map(({ bonusId, subtractValue }, idx) => ({
                    id: idx,
                    newFreeBetId: bonusId,
                    amount: subtractValue,
                }));

                return {
                    ...mappedBet,
                    freeBets: undefined,
                    freebetBonusSubtract: undefined,
                    freebetCredits,
                };
            }

            if (selectedBonuses !== undefined) {
                const betBonusesForSelection = 'id' in mappedBet ? selectedBonuses.get(mappedBet.id) : undefined;

                if (betBonusesForSelection !== undefined) {
                    const stakePerLineAmount = precision.newFromAnything(mappedBet.stakePerLine);

                    const decoratedStake =
                        mappedBet.eachWay === true ? stakePerLineAmount.multiply(2) : stakePerLineAmount;

                    const freebetCredits = betBonusesForSelection.reduce<
                        Array<{ id: number; newFreeBetId: string; amount: string }>
                    >((acc, bonus, idx) => {
                        const remainingStake = decoratedStake.sub(
                            acc.reduce((sum, credit) => sum.add(new Amount(credit.amount)), new Amount('0'))
                        );

                        if (remainingStake.isGreaterThanZero()) {
                            const bonusAmount = bonus.currentAmount;
                            const amountToUse = remainingStake.isGreaterThan(bonusAmount)
                                ? bonusAmount
                                : remainingStake;

                            acc.push({
                                id: idx,
                                newFreeBetId: bonus.id,
                                amount: amountToUse.value,
                            });
                        }

                        return acc;
                    }, []);

                    return {
                        ...mappedBet,
                        freeBets: undefined,
                        freebetBonusSubtract: undefined,
                        freebetCredits,
                    };
                }

                const betBonusesForCombination = selectedBonuses.get(mappedBet.type);

                if (betBonusesForCombination !== undefined) {
                    const stakePerLineAmount = precision.newFromAnything(mappedBet.stakePerLine);

                    const decoratedStake =
                        mappedBet.eachWay === true ? stakePerLineAmount.multiply(2) : stakePerLineAmount;

                    return {
                        ...mappedBet,
                        freeBets: undefined,
                        freebetBonusSubtract: undefined,
                        freebetCredits: betBonusesForCombination.reduce<
                            Array<{ id: number; newFreeBetId: string; amount: string }>
                        >((acc, bonus, idx) => {
                            const remainingStake = decoratedStake.sub(
                                acc.reduce((sum, credit) => sum.add(new Amount(credit.amount)), new Amount('0'))
                            );

                            if (remainingStake.isGreaterThanZero()) {
                                const bonusAmount = bonus.currentAmount;
                                const amountToUse = remainingStake.isGreaterThan(bonusAmount)
                                    ? bonusAmount
                                    : remainingStake;

                                acc.push({
                                    id: idx,
                                    newFreeBetId: bonus.id,
                                    amount: amountToUse.value,
                                });
                            }

                            return acc;
                        }, []),
                    };
                }

                return bet;
            }

            return mappedBet;
        });

    const rabBets: PlaceBetRabBetType[] = data.rabBets ?? [];
    const bets = [...regularBets, ...rabBets];

    const sendData = {
        bets,
        possibleBetSuccess: data.possibleBetSuccess,
    };

    const parsedData = PlaceBetNewSchema.safeParse(sendData);
    if (!parsedData.success) {
        console.error('Validation error - Check bets');
        return;
    }
    return parsedData.data;
};

const parseBettingPlaceBetResponse = (response: OpenapiBettingPlaceBetResponseType): ParsedPlaceBetType => {
    switch (response.status) {
        case 201:
            return {
                status: 'success' as const,
                data: response.body.data.bets,
            };
        case 422:
            return {
                status: 'error' as const,
                data: response.body,
            };
        default:
            return {
                status: 'internalError' as const,
                data: 'request_fail',
            };
    }
};

export const postPlaceBet = async (
    API_URL: string,
    API_UNIVERSE: string,
    body: PlaceBetRequestBodyType
): Promise<ParsedPlaceBetType> => {
    const { possibleBetSuccess, bets } = body;

    const URL = `${API_URL}/punter`;
    const betParams = {
        universe: API_UNIVERSE,
        requestBody: {
            bets,
        },
    };

    const extraHeaders = {
        Authorization: `Bearer ${$appState.common.session.currentJwt}`,
    };

    const parsedBetParams = ParamsTypeZOD.safeParse(betParams);
    if (!parsedBetParams.success) {
        return {
            status: 'internalError' as const,
            data: 'bet_params',
        };
    }

    const response = await openapiBettingPlaceBetRequest(URL, parsedBetParams.data, extraHeaders);

    const parsedData = parseBettingPlaceBetResponse(response);
    if (parsedData.status !== 'error') {
        return parsedData;
    }

    const { errors } = parsedData.data;

    if (!possibleBetSuccess && errors !== undefined && errors !== null) {
        const ignoreProblems = errors.map((err) => ({ ...err, details: {} }));
        const body = {
            ...betParams,
            requestBody: {
                ...betParams.requestBody,
                ignoreProblems,
            },
        };

        const parsedBody = ParamsTypeZOD.safeParse(body);
        if (!parsedBody.success) {
            return {
                status: 'internalError' as const,
                data: 'retry-fail',
            };
        }
        const retryData = await openapiBettingPlaceBetRequest(API_URL, parsedBody.data, extraHeaders);
        return parseBettingPlaceBetResponse(retryData);
    }
    return parsedData;
};
