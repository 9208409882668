import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Common, useCommon } from 'src/domains/common/Common';
import { ICON_POSITION, Popup } from 'src/domains/players/webview/ui/Popup';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    Header,
    Heading,
    ButtonWrapper,
    ContentWrapper,
    FreeBetIconWrapper,
} from 'src/domains/layouts/webview/components/popups/bonusEnginePopup/bonusEngine.style';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { action, makeObservable } from 'src_common/common/mobx-wrapper';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { BonusBalanceModel } from 'src/domains/players/state/BonusBalanceModel';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

class BonusEnginePopupState {
    public static get = AutoWeakMap.create((common: Common) => new BonusEnginePopupState(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);
    }

    @action public onClose = (): void => {
        const localStorage = LocalStorageState.get(this.common);
        localStorage.bonusEnginePopup.setValue(false);
    };

    @action public onViewCredit = (): void => {
        const router = StarRouter.get(this.common);
        router.redirectToAccountSummary();
        this.onClose();
    };
}

export const BonusEnginePopup = observer('BonusEnginePopup', (): JSX.Element | null => {
    const common = useCommon();
    const localStorage = LocalStorageState.get(common);
    const state = BonusEnginePopupState.get(common);
    const bonusBalanceModel = BonusBalanceModel.get(common);
    const freeBetsAmount = bonusBalanceModel.processedBonusBalances.freeBets;
    const freeSpinAmount = bonusBalanceModel.processedBonusBalances.freeSpins;
    const shouldShowBonusEnginePopup = localStorage.bonusEnginePopup.getValue();

    if (common.session.isAuthorized === false || (freeBetsAmount === 0 && freeSpinAmount === 0)) {
        return null;
    }

    if (shouldShowBonusEnginePopup === true) {
        return (
            <Popup
                maxWidth='288px'
                icon={<FreeBetIconWrapper />}
                options={{
                    iconOptions: { position: ICON_POSITION.TOP },
                }}
            >
                <Header>
                    <Heading>
                        <I18n
                            langKey='bonus-engine-popup.header'
                            defaultText='Congratulations'
                        />
                    </Heading>
                </Header>
                <ContentWrapper>
                    <I18n
                        langKey='bonus-engine.popup.description'
                        defaultText='You have received free credits to enjoy free bets and/or spins! Dive in and make the most of this chance to win big!'
                    />
                </ContentWrapper>

                <footer>
                    <ButtonWrapper
                        size='medium'
                        version='primary'
                        onClick={state.onViewCredit}
                    >
                        <I18n
                            langKey='bonus-engine.popup.view-credits'
                            defaultText='View Credits'
                        />
                    </ButtonWrapper>

                    <ButtonWrapper
                        size='medium'
                        version='secondary-light'
                        onClick={state.onClose}
                    >
                        <I18n
                            langKey='bonus-engine.popup.close'
                            defaultText='Close'
                        />
                    </ButtonWrapper>
                </footer>
            </Popup>
        );
    }

    return null;
});
