export const isMobileVersionEqualOrHigher = (version: string | null, checkVersion: string | null): boolean => {
    if (checkVersion === null || version === null) {
        return false;
    }
    const versionParts = version.split('.');
    const checkVersionParts = checkVersion.split('.');

    for (let i = 0; i < 3; i++) {
        const v = parseInt(versionParts[i] ?? '0', 10);
        const cv = parseInt(checkVersionParts[i] ?? '0', 10);

        if (v < cv) return false;
        if (v > cv) return true;
    }

    return true;
};
