import { observer } from 'src/utils/mobx-react';
import { DepositYaspaPageType, ViewType, YaspaState } from './YaspaSegment.state';
import React, { useState } from 'react';
import { YaspaContainer, YaspaDepositButton, Separator, PoweredByYaspaLogo } from './YaspaSegment.style';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { useCommon } from 'src/domains/common/Common';
import PoweredByYaspa from 'src/domains/players/webview/components/WithdrawAndDeposit/assets/PoweredByYaspa.png';
import PoweredByYaspaSignup from 'src/domains/players/webview/components/WithdrawAndDeposit/assets/PoweredByYaspaSignup.png';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface YaspaSegmentPropsType {
    depositYaspaPage: DepositYaspaPageType;
    viewType: ViewType;
    isSignup?: boolean;
    showSeparator?: boolean;
}

export const YaspaSegment = observer(
    'YaspaSegment',
    ({
        depositYaspaPage,
        viewType,
        isSignup = false,
        showSeparator = true,
    }: YaspaSegmentPropsType): JSX.Element | null => {
        const common = useCommon();
        const { appPlayersState } = useAppStateContext();
        const { usersState } = appPlayersState;
        const [state] = useState(() => new YaspaState(viewType, common, depositYaspaPage, usersState));
        const basicDataModel = BasicDataModel.get(common);
        const config = ConfigComponents.get(common);

        if (basicDataModel.currency === 'EUR' && config.config.useOpenBanking?.isEuroAllowed === false) {
            return null;
        }

        return (
            <YaspaContainer>
                {showSeparator ? (
                    <Separator
                        isSignup={isSignup}
                        data-test='separator'
                    >
                        <span>Or</span>
                    </Separator>
                ) : null}
                <YaspaDepositButton
                    size='large'
                    disabled={state.isButtonDisabled}
                    onClick={state.submitDepositForm}
                    dataTest='yaspa-deposit-button'
                >
                    {viewType === 'withdraw' ? (
                        <I18n
                            langKey='yaspa.button.start.journey.withdrawal'
                            defaultText='Instant Bank Withdrawal'
                        />
                    ) : (
                        <I18n
                            langKey='yaspa.button.start.journey.deposit'
                            defaultText='Instant Bank Deposit'
                        />
                    )}
                </YaspaDepositButton>
                {state.errors.length > 0
                    ? state.errors.map((error) => (
                          <Messages
                              type='error'
                              marginTop='8px'
                              key={error.type}
                              message={error.message}
                          />
                      ))
                    : null}
                <PoweredByYaspaLogo
                    isSignup={isSignup}
                    src={isSignup ? PoweredByYaspaSignup : PoweredByYaspa}
                    alt='Powered by yaspa'
                    data-test='powered-by-yaspa-logo'
                />
            </YaspaContainer>
        );
    }
);
