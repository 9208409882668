import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface RewardIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const RewardIcon = observer('RewardIcon', ({ className, onClick }: RewardIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            width={17}
            height={16}
            fill='none'
            onClick={onClick}
        >
            <path
                fill='currentcolor'
                fillRule='evenodd'
                d='M8.5 15.333A7.333 7.333 0 1 0 8.5.667a7.333 7.333 0 0 0 0 14.666Zm.505-11.188a.533.533 0 0 0-1.01 0l-.768 2.25a.533.533 0 0 1-.333.332l-2.25.768a.533.533 0 0 0 0 1.01l2.25.768a.533.533 0 0 1 .333.332l.768 2.25a.533.533 0 0 0 1.01 0l.768-2.25a.533.533 0 0 1 .332-.332l2.25-.768a.533.533 0 0 0 0-1.01l-2.25-.768a.533.533 0 0 1-.332-.332l-.768-2.25Z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
