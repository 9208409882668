import { computed, makeObservable, observable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { getCookie } from 'src/domains/layouts/config/config';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { FinalStepType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';
import { Amount } from 'src_common/common/amount/Amount';
import { UsersState } from 'src/domains/players/state/UsersState';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

type YaspaIframeStateType =
    | {
          type: 'iframe-view';
      }
    | {
          type: 'success-view';
      }
    | FinalStepType;

export class YaspaDepositState {
    @observable.ref private isChecking: boolean = false;
    @observable public step: YaspaIframeStateType = { type: 'loading-view' };

    public constructor(
        private readonly usersState: UsersState,
        private readonly common: Common,
        private readonly yaspaViewIncoming: 'iframe-view' | 'loading-view' | 'success-view'
    ) {
        makeObservable(this);

        this.step = { type: this.yaspaViewIncoming };
        this.checkTransactionStatus().catch(console.error);
    }

    private async checkYaspaTransactionStatus(): Promise<FinalStepType | null> {
        const transactionIdFromCookie = getCookie('yaspaTransactionId');
        if (transactionIdFromCookie === null || transactionIdFromCookie.trim() === '') {
            return {
                type: 'failure-view',
                failType: 'contactGeneralIssue',
            };
        }

        const transactionId = parseInt(transactionIdFromCookie, 10);

        const response = await this.common.trpcClient.client.payments.checkoutCallbackYaspa.query({
            transactionId,
        });

        if (
            response.responseStatus === 'error' ||
            response.response.status === 'internal-error' ||
            response.response.status === 'unknown'
        ) {
            return {
                type: 'failure-view',
                failType: 'contactGeneralIssue',
            };
        }

        if (response.response.status === 'cancelled') {
            return {
                type: 'failure-view',
                failType: 'cancelledTransaction',
            };
        }

        if (response.response.status === 'failed' || response.response.status === 'rejected') {
            const {
                status,
                createdAt,
                currency,
                citizenTransactionId,
                transactionId: responseTransactionId,
                paymentProvider,
                amount,
            } = response.response;

            return {
                type: 'failure-view',
                failType: 'failWithReceipt',
                data: {
                    data: {
                        orderId: citizenTransactionId,
                        createdAt,
                        currency,
                        status,
                        paymentProvider,
                        amount,
                    },
                    transactionId: responseTransactionId,
                },
            };
        }

        if (response.response.status === 'paid') {
            return {
                type: 'success-view',
            };
        }

        return null;
    }

    private async checkTransactionStatus(): Promise<void> {
        if (this.isChecking || this.yaspaViewIncoming === 'success-view') return;
        this.isChecking = true;

        if (this.yaspaViewIncoming === 'loading-view') {
            const response = await this.checkYaspaTransactionStatus();
            this.step = response ?? {
                type: 'failure-view',
                failType: 'contactGeneralIssue',
            };
        } else {
            await timeout(10000);

            for (let i = 0; i < 75; i++) {
                const response = await this.checkYaspaTransactionStatus();
                if (response === null) {
                    await timeout(4000);
                    continue;
                }
                this.step = response;
                break;
            }
        }

        this.isChecking = false;
    }

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return BasicDataModel.get(this.common).money(new Amount(playableBalance));
        }
    }
}
