import { action, computed, makeObservable, observable } from 'mobx';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { Amount } from 'src_common/common/amount/Amount';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { generateRandomAlphanumeric } from 'src/domains/players/webview//components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/generateRandomAlphanumeric';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    apiDepositInitiateRequest,
    ApiDepositInitiateResponse200Type,
} from 'src/api_openapi/v2/yaspa/apiDepositInitiate';
import {
    YaspaBankingApiYaspaPayInRequestType,
    YaspaBankingApiPayOutRequestType,
} from 'src/api_openapi/v2/yaspa/schemas';
import { apiWithdrawInitiateRequest } from 'src/api_openapi/v2/yaspa/apiWithdrawInitiate';
import { UsersState } from 'src/domains/players/state/UsersState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { RemainingLimitState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit.state';
import { setCookie } from 'src/domains/layouts/config/config';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';

export type ViewType = 'withdraw' | 'deposit';

export interface DepositCustomerDataType {
    requestType: 'deposit';
    body: YaspaBankingApiYaspaPayInRequestType;
}

export interface WithdrawCustomerDataType {
    requestType: 'withdraw';
    body: YaspaBankingApiPayOutRequestType;
}

export type CustomerDataType = DepositCustomerDataType | WithdrawCustomerDataType;
export interface YaspaUrlResponseType {
    link: string;
}

export interface DepositYaspaPageType {
    isSignup: boolean;
    getAmountInput: () => FormInputState<string, Amount>;
    handleRedirectToYaspaFrame: (link: YaspaUrlResponseType['link']) => void;
}

type ErrorType = 'depositRestriction' | 'unknown';

export interface YaspaErrorType {
    type: ErrorType;
    message: string;
}

export class YaspaState {
    @observable private isSubmitting: boolean = false;
    @observable public errors: YaspaErrorType[] = [];
    @observable public lastTransactionId: number | undefined = undefined;
    private readonly languagesState: LanguagesState;
    private readonly accountHelperMail: string;
    public readonly remainingLimitState: RemainingLimitState;
    private readonly environmentState: EnvironmentState;
    public constructor(
        private readonly viewType: ViewType,
        private readonly common: Common,
        private depositYaspaPage: DepositYaspaPageType,
        private usersState: UsersState
    ) {
        makeObservable(this);
        this.languagesState = LanguagesState.get(common);
        const configComponents = ConfigComponents.get(common);
        this.environmentState = EnvironmentState.get(common);
        this.accountHelperMail = configComponents.config.accountHelperMail;
        this.remainingLimitState = new RemainingLimitState(common, usersState);
    }

    @computed public get getResponseLinks(): {
        successRedirectUrl: string;
        failureRedirectUrl: string;
    } {
        const deviceParam =
            this.environmentState.mobileAppType === 'ios-app'
                ? '?deviceType=ios'
                : this.environmentState.mobileAppType === 'android-app'
                  ? '?deviceType=android'
                  : '';

        return {
            successRedirectUrl: `/yaspa-banking-api/v1/deposit/callback/success${deviceParam}`,
            failureRedirectUrl: `/yaspa-banking-api/v1/deposit/callback/failure${deviceParam}`,
        };
    }

    @computed public get customerData(): CustomerDataType | null {
        const customer = BasicDataModel.get(this.common).basicDataReady;
        if (customer === null) {
            return null;
        }

        const amountInput = this.depositYaspaPage.getAmountInput();
        if (amountInput.result.value.type === 'error') {
            return null;
        }

        const { gatewayApiHost } = this.common.envVariables;
        if (gatewayApiHost === null) {
            this.createErrorMessage('Unknown gatewayApiHost');
            return null;
        }

        const randomReferenceNumber = generateRandomAlphanumeric(15);
        if (this.viewType === 'deposit') {
            return {
                requestType: 'deposit',
                body: {
                    customerIdentifier: customer.id.toString(),
                    paymentGiro: customer.currency === 'GBP' ? 'FPS' : 'SEPA',
                    amount: amountInput.result.value.data.toFixed(2),
                    reference: randomReferenceNumber,
                    journeyType: 'HOSTED_PAYMENT',
                    currency: customer.currency,
                    supportedCountries: ['GB'], // hardcoded on BE
                    successBankRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.successRedirectUrl}`,
                    successRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.successRedirectUrl}`,
                    failureBankRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.failureRedirectUrl}`,
                    failureRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.failureRedirectUrl}`,
                    searchableText: `${this.common.envVariables.universe}-${customer.id}`,
                },
            };
        } else {
            return {
                requestType: 'withdraw',
                body: {
                    customerIdentifier: customer.id.toString(),
                    paymentGiro: customer.currency === 'GBP' ? 'FPS' : 'SEPA',
                    amount: amountInput.result.value.data.toFixed(2),
                    reference: randomReferenceNumber,
                    currency: customer.currency,
                    description: 'withdraw',
                    supportedCountries: ['GB'],
                    language: 'EN',
                    successBankRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.successRedirectUrl}`,
                    successRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.successRedirectUrl}`,
                    failureBankRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.failureRedirectUrl}`,
                    failureRedirectUrl: `${gatewayApiHost}${this.getResponseLinks.failureRedirectUrl}`,
                    channel: 'default',
                },
            };
        }
    }

    @computed public get isButtonDisabled(): boolean {
        if (this.customerData === null) {
            return true;
        }

        if (this.isSubmitting) {
            return true;
        }

        return this.checkRequestedAmountForWithdraw;
    }

    @computed private get checkRequestedAmountForWithdraw(): boolean {
        if (this.viewType === 'withdraw') {
            const amountInput = this.depositYaspaPage.getAmountInput();
            if (amountInput.result.value.type === 'error') {
                return true;
            }

            const withdrawableBalance = this.usersState.walletData.valueReady?.withdrawableBalance;
            if (withdrawableBalance === undefined) {
                return true;
            }

            const withdrawableBalanceAmount = new Amount(withdrawableBalance);

            if (amountInput.result.value.data.isGreaterThan(withdrawableBalanceAmount)) {
                return true;
            }
        }
        return false;
    }

    @action public submitDepositForm = async (): Promise<void> => {
        if (this.customerData === null) {
            this.createErrorMessage('Unknown customer data');
            return;
        }

        try {
            this.isSubmitting = true;
            this.errors = [];

            const oldFormatAmount = ConfigComponents.get(this.common).precision.valueOldFormat(
                new Amount(this.customerData.body.amount)
            );

            if (this.customerData.requestType === 'deposit') {
                this.trackDeposit(oldFormatAmount, this.depositYaspaPage.isSignup);

                const ringFencedFunds = BasicDataModel.get(this.common).basicDataReady?.ringFencedFunds ?? false;

                if (ringFencedFunds === false) {
                    await this.usersState.onChangeRingFencedFlag();
                }
            }

            const urlResponseData = await this.getUrlData(this.customerData);

            if (urlResponseData === null) return;

            this.depositYaspaPage.handleRedirectToYaspaFrame(urlResponseData);
        } catch (e) {
            this.createErrorMessage();
        } finally {
            this.isSubmitting = false;
        }
    };

    private trackDeposit(oldFormatAmount: number, isSignup: boolean): void {
        const googleTagManager = GoogleTagManagerState.get(this.common);
        if (isSignup) {
            googleTagManager.depositedMoney(oldFormatAmount, true);
            googleTagManager.gtmSignUpStepFour(oldFormatAmount);
        } else {
            googleTagManager.depositedMoney(oldFormatAmount, false);
        }
    }

    private getUrlData = async (
        customerData: CustomerDataType
    ): Promise<ApiDepositInitiateResponse200Type['data']['link'] | null> => {
        const { gatewayApiHost } = this.common.envVariables;
        const customerIdentifier = customerData.body.customerIdentifier;

        if (gatewayApiHost === null) {
            this.createErrorMessage('Unknown gatewayApiHost');
            return null;
        }
        const requestId = `${this.common.envVariables.universe}-${customerIdentifier}`;
        if (customerData.requestType === 'deposit') {
            const response = await apiDepositInitiateRequest(
                gatewayApiHost,
                { requestBody: customerData.body },
                { 'x-client-request-id': requestId }
            );

            if (response.status !== 200) {
                this.createErrorMessage(response.body.errors?.[0]?.message);
                return null;
            }
            const { link, transactionId } = response.body.data;

            if (transactionId !== undefined) {
                setCookie('yaspaTransactionId', transactionId.toString(), 0.041666667, 'Lax'); // 1 hour = 1/24 day
            }

            return link;
        } else {
            const response = await apiWithdrawInitiateRequest(
                gatewayApiHost,
                { requestBody: customerData.body },
                { 'x-client-request-id': requestId }
            );

            if (response.status !== 200) {
                this.createErrorMessage(response.body.errors?.[0]?.message);
                return null;
            }
            return response.body.data.link;
        }
    };

    @action public createErrorMessage = (message?: string): void => {
        const errorType: ErrorType =
            message?.includes('depositRestriction') === true ? 'depositRestriction' : 'unknown';

        switch (errorType) {
            case 'depositRestriction':
                this.errors.push({
                    type: 'depositRestriction',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.yaspa-deposit-restriction',
                        'Your deposits have been restricted. In case of any questions, please get in touch with us at {email}.',
                        {
                            email: this.accountHelperMail,
                        }
                    ),
                });
                break;

            case 'unknown':
                this.errors.push({
                    type: 'unknown',
                    message: message ?? 'Unknown error occurred',
                });
                break;
        }
    };
}
