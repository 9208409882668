// iconvert is added via gtm only for rhino 10.02.2025

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        iconvert:
            | {
                  loaded: boolean;
                  trackRegistration: () => void;
                  trackDeposit: (depositAmount: string, currencyCode: string) => void;
                  trackUserLogin: (userId: number) => void;
              }
            | undefined;
    }
}

export const iConvertReg = (): void => {
    if (window.iconvert !== undefined && window.iconvert.loaded) {
        window.iconvert.trackRegistration();
    }
};

export const iConvertDeposit = (depositAmount: string, currencyCode: string): void => {
    if (window.iconvert !== undefined && window.iconvert.loaded) {
        window.iconvert.trackDeposit(depositAmount, currencyCode);
    }
};

export const iConvertLogin = (userId: number): void => {
    if (window.iconvert !== undefined && window.iconvert.loaded) {
        window.iconvert.trackUserLogin(userId);
    }
};
