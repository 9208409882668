import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    InfoIconBox,
    WarningIconWrapper,
    DepositTitle,
    DepositIssueWrapper,
    IssueDepositTitle,
    IssueDepositInfo,
    MailWrapper,
    ButtonWrapper,
    ButtonContainer,
    InfoIconWrapper,
    WarningIconBox,
    IssueDepositInfoSecondary,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure.style';
import { TransactionReceipt } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/transactionReceipt/TransactionReceipt';
import { TransactionCallbackType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';

export type DepositViewType = 'serverIssue' | 'contactGeneralIssue' | 'failWithReceipt' | 'cancelledTransaction';
interface DepositFailurePropsType {
    issueType: DepositViewType;
    accountHelperMail: string;
    transactionCallback?: TransactionCallbackType;
    hideDepositSuccess?: () => void;
    isSignup: boolean;
}

const serverIssue = (
    <>
        <InfoIconBox data-test='info-icon-box'>
            <InfoIconWrapper />
        </InfoIconBox>
        <IssueDepositTitle data-test='issue-deposit-title'>
            <I18n
                langKey='account.top-up.server-issue.small-title'
                defaultText='Payments are unavailable at this time'
            />
        </IssueDepositTitle>
        <IssueDepositInfoSecondary data-test='issue-deposit-info-secondary'>
            <I18n
                langKey='account.top-up.server-issue.info'
                defaultText='We are working with our providers to rectify the issue and apologise for any inconvenience caused. For more information contact customer support'
            />
        </IssueDepositInfoSecondary>
    </>
);

const canceledTransaction = (
    <>
        <InfoIconBox data-test='info-icon-box'>
            <InfoIconWrapper />
        </InfoIconBox>
        <IssueDepositTitle data-test='issue-deposit-title'>
            <I18n
                langKey='account.top-up.canceled-transaction.small-title'
                defaultText='The payment has been canceled'
            />
        </IssueDepositTitle>
        <IssueDepositInfoSecondary data-test='issue-deposit-info-secondary'>
            <I18n
                langKey='account.top-up.canceled-transaction.info'
                defaultText='You have not been charged.'
            />
        </IssueDepositInfoSecondary>
    </>
);

const contactGeneralIssue = (accountHelperMail: string): JSX.Element => (
    <>
        <WarningIconBox>
            <WarningIconWrapper />
        </WarningIconBox>
        <DepositTitle>
            <I18n
                langKey='account.top-up.contact-general.title'
                defaultText='Deposit Unsuccessful'
            />
        </DepositTitle>
        <IssueDepositInfo>
            <I18n
                langKey='account.top-up.contact-general-first-part.info'
                defaultText='Your deposit has been declined, please contact '
            />
            <MailWrapper>
                <I18n
                    langKey='account.top-up.contact-general-email.info'
                    defaultText='{accountHelperMail}'
                    params={{ accountHelperMail }}
                />
            </MailWrapper>
            <I18n
                langKey='account.top-up.contact-general-second-part.info'
                defaultText=' or your bank if you wish to discuss this matter further.'
            />
        </IssueDepositInfo>
    </>
);

const failWithReceipt = (
    transactionCallback: TransactionCallbackType,
    hideDepositSuccess: () => void,
    accountHelperMail: string,
    isSignup: boolean
): JSX.Element => {
    return (
        <>
            <WarningIconBox>
                <WarningIconWrapper />
            </WarningIconBox>
            <DepositTitle data-test='deposit-title'>
                <I18n
                    langKey='account.top-up.fail-with-receipt.title'
                    defaultText='Deposit Unsuccessful'
                />
            </DepositTitle>
            <IssueDepositInfo data-test='issue-deposit-info'>
                <I18n
                    langKey='account.top-up.fail-with-receipt.paragraph'
                    defaultText='We could not process your deposit at this time, please try again or contact our support team.'
                />
            </IssueDepositInfo>
            <TransactionReceipt
                viewType='failure'
                transactionCallback={transactionCallback}
            />
            <ButtonContainer>
                <ButtonWrapper
                    onClick={hideDepositSuccess}
                    size='large'
                    dataTest='try-again-button'
                >
                    <I18n
                        langKey='account.top-up.fail-with-receipt.try-again'
                        defaultText='Try Again'
                    />
                </ButtonWrapper>
            </ButtonContainer>
            <ButtonContainer>
                <a href={`mailto: ${accountHelperMail}`}>
                    <ButtonWrapper
                        size='large'
                        version={isSignup ? 'secondary-dark' : 'secondary-light'}
                        dataTest='contact-support-button'
                    >
                        <I18n
                            langKey='account.top-up.fail-with-receipt.button.contact-support'
                            defaultText='Contact Support'
                        />
                    </ButtonWrapper>
                </a>
            </ButtonContainer>
        </>
    );
};

export const DepositFailure = observer(
    'DepositFailure',
    ({ issueType, accountHelperMail, transactionCallback, hideDepositSuccess, isSignup }: DepositFailurePropsType) => {
        const body = (issueType: DepositViewType): JSX.Element => {
            switch (issueType) {
                case 'contactGeneralIssue':
                    return contactGeneralIssue(accountHelperMail);
                case 'serverIssue':
                    return serverIssue;
                case 'failWithReceipt':
                    if (transactionCallback === undefined || hideDepositSuccess === undefined) {
                        return serverIssue;
                    } else {
                        return failWithReceipt(transactionCallback, hideDepositSuccess, accountHelperMail, isSignup);
                    }
                case 'cancelledTransaction': //yaspa
                    return canceledTransaction;
                default:
                    return contactGeneralIssue(accountHelperMail);
            }
        };

        return <DepositIssueWrapper isSignup={isSignup}>{body(issueType)}</DepositIssueWrapper>;
    }
);
