import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { OpenBetsBtnWrapper } from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import {
    BetslipReceiptHeader,
    BetslipReceiptWrapper,
    Text,
    ListElement,
    ReceiptValue,
    SmallText,
    BetslipReceiptIconWrapper,
    BetslipReceiptGiftIconWrapper,
    ListWrapper,
    BonusEngineFreeBetExcludedReturnsText,
} from './BetslipReceipt.style';

export const BetslipReceipt = observer('BetslipReceipt', () => {
    const appState = useAppStateContext();
    const {
        appLayoutsState,
        appSportsBookState,
        config: { bonusEngine },
    } = appState;

    const { betSlipState, betWidgetsState } = appSportsBookState;
    const { betReceipt } = betSlipState.betSlipSummaryState;

    const { lifeSpanState, sdkCustomer } = appLayoutsState;
    const lifeSpanPotentialReturn = lifeSpanState.lifeSpanSocketState.rewardValueForBetslipReceipt;

    const isLK = appState.env.envVariables.universe === 'luckyking';
    const onClick = (): void => betWidgetsState.openMyBetsFromReceipt();

    if (betReceipt === null) {
        return null;
    }

    return (
        <BetslipReceiptWrapper
            className='betslip__content'
            key='Betslip:receipt'
        >
            <BetslipReceiptHeader>
                <I18n
                    langKey='betslip.bet-receipt.header'
                    defaultText='BET RECEIPT'
                />
            </BetslipReceiptHeader>
            <Text data-test='bet-receipt-paragraph'>
                <BetslipReceiptIconWrapper />
                <I18n
                    langKey='betslip.bet-receipt.description'
                    defaultText='Your bets have been successfully placed'
                />
            </Text>
            <ListWrapper>
                <ListElement>
                    <span data-test='label-bet-receipt-stake'>
                        {betReceipt.isFreeBet ? (
                            <I18n
                                langKey='betslip.bet-receipt.free-bet-stake.label'
                                defaultText='Free Bet Credit Stake'
                            />
                        ) : (
                            <I18n
                                langKey='betslip.bet-receipt.stake.label'
                                defaultText='Total Stake'
                            />
                        )}
                    </span>
                    <ReceiptValue data-test='value-bet-receipt-stake'>
                        {betReceipt.isFreeBet ? <BetslipReceiptGiftIconWrapper /> : null}
                        {betReceipt.totalBonusEngineFreeBetsAmount === null
                            ? sdkCustomer.money(betReceipt.totalStake)
                            : sdkCustomer.money(betReceipt.totalBonusEngineFreeBetsAmount)}
                    </ReceiptValue>
                </ListElement>
                {betReceipt.totalCashStake === null ||
                betReceipt.totalCashStake.isLessOrEqual(new Amount('0')) ? null : (
                    <ListElement>
                        <span data-test='label-bet-receipt-cash-stake'>
                            <I18n
                                langKey='betslip.bet-receipt.cash-stake.label'
                                defaultText='Cash Stake'
                            />
                        </span>
                        <ReceiptValue data-test='value-bet-receipt-stake'>
                            {sdkCustomer.money(betReceipt.totalCashStake)}
                        </ReceiptValue>
                    </ListElement>
                )}
                <ListElement>
                    <span data-test='label-bet-receipt-total-potential-returns'>
                        {betReceipt.totalBonusEngineFreeBetsAmount === null ? (
                            <I18n
                                langKey='betslip.bet-receipt.potential-returns.label'
                                defaultText='Total Potential Returns'
                            />
                        ) : (
                            <I18n
                                langKey='betslip.bet-receipt.total-returns.label'
                                defaultText='Total Returns'
                            />
                        )}
                    </span>
                    <ReceiptValue data-test='value-bet-receipt-total-potential-returns'>
                        {betReceipt.totalPotentialReturns.isEqualWith(new Amount('0'))
                            ? 'n/a'
                            : sdkCustomer.money(betReceipt.totalPotentialReturns.add(lifeSpanPotentialReturn))}
                        {betReceipt.totalBonusEngineFreeBetsAmount === null ? '' : '*'}
                    </ReceiptValue>
                    {betReceipt.isFreeBet && !bonusEngine ? (
                        <SmallText>
                            <I18n
                                langKey='betslip.bet-receipt.return-exclude-stake.label'
                                defaultText='Return exclude stake'
                            />
                        </SmallText>
                    ) : null}
                </ListElement>
                {betReceipt.isFreeBet && !bonusEngine ? (
                    <ListElement>
                        <span data-test='label-bet-receipt-remaining-free-bet-balance'>
                            <I18n
                                langKey='betslip.bet-receipt.remaining-free-bet-balance.label'
                                defaultText='Remaining Free Bet Balance'
                            />
                        </span>
                        <ReceiptValue
                            className='receipt-value'
                            data-test='value-bet-receipt-remaining-free-bet-balance'
                        >
                            {sdkCustomer.money(
                                betSlipState.freeBetsAmount === null ? null : betSlipState.freeBetsAmount
                            )}
                        </ReceiptValue>
                    </ListElement>
                ) : null}
            </ListWrapper>
            <OpenBetsBtnWrapper
                isButton={true}
                size='large'
                onClick={isLK ? onClick : undefined}
                to={isLK ? undefined : { account: 'bet-history' }}
                dataTest='bet-receipt-open-bets-button'
            >
                <I18n
                    langKey='betslip.bet-receipt.open-bets-button.label'
                    defaultText='Open Bets'
                />
            </OpenBetsBtnWrapper>
            {betReceipt.totalBonusEngineFreeBetsAmount !== null && (
                <BonusEngineFreeBetExcludedReturnsText>
                    *
                    <I18n
                        langKey='betslip.bet-receipt.bonuse-engine.stake-excluded'
                        defaultText='Free Bet stake excluded in returns.'
                    />
                </BonusEngineFreeBetExcludedReturnsText>
            )}
        </BetslipReceiptWrapper>
    );
});
