import { computed, makeObservable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';

class MediaQueryConnectWrapper {
    public constructor(private readonly minWidth: number) {}

    public connect(mqlWrapper: MobxValue<boolean | null>): () => void {
        // tslint:disable-next-line:strict-type-predicates
        if (typeof window === 'undefined') {
            return (): void => {};
        }

        const handleBreakpoint = (event: MediaQueryListEvent): void => {
            mqlWrapper.setValue(event.matches);
        };

        const mql = window.matchMedia(`(min-width: ${this.minWidth}px)`);

        // tslint:disable-next-line:strict-type-predicates
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (mql.addEventListener === undefined) {
            mql.addListener(handleBreakpoint); // for Safari <= 13
        } else {
            mql.addEventListener('change', handleBreakpoint);
        }

        mqlWrapper.setValue(mql.matches);

        return (): void => {
            // tslint:disable-next-line:strict-type-predicates
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (mql.removeEventListener === undefined) {
                mql.removeListener(handleBreakpoint); // for Safari <= 13
            } else {
                mql.removeEventListener('change', handleBreakpoint);
            }
        };
    }

    public dispose(disposeResource: () => void): void {
        disposeResource();
    }
}

class BreakpointDeviceState {
    private readonly mqlWrapper: MobxValue<boolean | null>;

    public constructor(private readonly minWidth: number) {
        makeObservable(this);
        this.mqlWrapper = MobxValue.create({
            initValue: null,
            connect: new MediaQueryConnectWrapper(this.minWidth),
        });
    }

    @computed public get isBiggerOrEq(): boolean | null {
        return this.mqlWrapper.getValue();
    }
}

export const deviceSize = {
    /** xxLargeDesktop: 1440 */
    xxLargeDesktop: 1440,
    /** largeDesktop: 1200 */
    largeDesktop: 1200,
    /** desktop: 1024 */
    desktop: 1024,
    /** tablet: 768 */
    tablet: 768,
    /** largeMobile: 480 */
    largeMobile: 480,
    /** mobile: 415 */
    mobile: 415,
};

export class BreakpointsState {
    /** xxlargeDesktop: 1440px */
    public readonly xxLargeDesktop: BreakpointDeviceState;
    /** largeDesktop: 1200px */
    public readonly largeDesktop: BreakpointDeviceState;
    /** desktop: 1024 */
    public readonly desktop: BreakpointDeviceState;
    /** tablet: 768 */
    public readonly tablet: BreakpointDeviceState;
    /** largeMobile: 480 */
    public readonly largeMobile: BreakpointDeviceState;
    /** mobile: 415 */
    public readonly mobile: BreakpointDeviceState;

    public static get = AutoWeakMap.create((_common: Common) => new BreakpointsState());

    private constructor() {
        this.xxLargeDesktop = new BreakpointDeviceState(deviceSize.xxLargeDesktop);
        this.largeDesktop = new BreakpointDeviceState(deviceSize.largeDesktop);
        this.desktop = new BreakpointDeviceState(deviceSize.desktop);
        this.tablet = new BreakpointDeviceState(deviceSize.tablet);
        this.largeMobile = new BreakpointDeviceState(deviceSize.largeMobile);
        this.mobile = new BreakpointDeviceState(deviceSize.mobile);
    }
}
