import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { GiftIcon } from 'src/domains/layouts/shared/assets/icons/GiftIcon';

export const Header = styled('div', { label: 'Header' })`
    text-align: center;
`;

export const Heading = withConfig(theme => styled('h4', { label: 'Heading' })`
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    margin: 8px 0;
`);

export const ContentWrapper = withConfig(theme => styled('div', { label: 'ContentWrapper' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.5;
    margin-bottom: 24px;
    text-align: center;
`);

export const ButtonWrapper = styled(Button, { label: 'ButtonWrapper' })`
    display: block;
    width: 100%;
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`;

export const FreeBetIconWrapper = styled(GiftIcon, { label: 'FreeBetIconWrapper' })`
    fill: currentcolor;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
    width: 48px;
`;
