import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';

const BonusEngineIO = t.boolean;
const decode = buildValidator('localStorageConfig.bonusEnginePopup', BonusEngineIO, true);

export const localStorageBonusEnginePopup = (
    withAutorun: boolean,
    key: string
): LocalStorageItemState<boolean | null> => {
    // true if it should be shown
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: true,
        useSessionStorage: true,
    });
};
